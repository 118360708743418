import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { ComiteSearchComponent } from '@features-evaluateur/comites/comites-list/comite-search/comite-search.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ComiteSearchComponent],
  imports: [CommonModule, SharedModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, SharedUiModule],
  exports: [ComiteSearchComponent],
})
export class ComiteSearchModule {}
